<template>
  <div>
    <ciam-select :isDisabled="isDisabled" :options="OptionalizeList(backups)" v-model="currentBackup"
      @input="onBackupChange()" />
  </div>
</template>

<script>
import DeploymentService from '../pages/deployments/DeploymentService';
import CiamSelect from '@/components/CiamSelect.vue';
export default {
  components: {
    CiamSelect,
  },
  props: {
    isDisabled: {
      required: false
    },
    backups: {
      type: Array,
      required: true,
    },
    currentInstanceId: {
      type: String
    }
  },
  data() {
    return {
      itemsPerPage: 5,
      currentPage: 1,
      currentBackup: "",
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.backups.length / this.itemsPerPage);
    },
    paginatedBackups() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      return this.backups.slice(start, start + this.itemsPerPage);
    },
  },
  methods: {
    onBackupChange() {
      this.$emit('update-backup', this.currentBackup);
    },
    async restoreBackup(filename) {
      const parameters = {
        bootstrap: {
          restoreFromFile: filename
        }
      };
      return await DeploymentService.restoreInstance(this.currentInstance.instanceId, parameters);
    },

    OptionalizeList(backups) {
      if (this.isDisabled) {
        return [{ label: "", value: null }]
      }
      return backups.map(backup => {
        return {
          label: this.convertFileDateToLocal(backup.filename),
          value: backup.filename
        }
      })
    },
    convertFileDateToLocal(filename, userTimeZone = 'Europe/Paris') {
      const dateRegex = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})(\+0000)/;
      const match = filename.match(dateRegex);

      if (!match) {
        throw new Error("File name is not in a valid date format");
      }

      const utcDate = match[1];

      const date = new Date(utcDate + "Z");

      try {
        const localDate = date.toLocaleString("fr-FR", {
          timeZone: userTimeZone,
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        });
        return localDate;
      } catch (e) {
        console.error("Error while converting the date", e);
      }
    }
  },
};
</script>